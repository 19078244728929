import React from "react";
import "./styles.css";

import CtaTelWpp from "../../components/CTATelWpp";

import menu from "../../assets/menu.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <div className="left">
          <figure>
            <img src={menu} alt="menu" />
          </figure>

          <p className="text">Atuamos em toda a Teresina, Piauí</p>
        </div>

        <article className="right">
          <CtaTelWpp />
        </article>
      </section>
    </nav>
  );
};

export default Menu;
