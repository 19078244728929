import React from "react";
import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure className="top">
            <img src={logo_rodape} alt="Logo" />
          </figure>

          <p className="text">
            <b>Endereço:</b> <br />
            Rua Luiz Vieira bairro São Sebastião S/N Teresina-PI.
          </p>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
            Centro de Tratamento Terapêutico Recomeço - Todos os direitos
            reservados.
          </p>

          <p className="text2">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
