import React from "react";
import "./index.css";

import formPag from "../../assets/formPag.png";

const FormasPagamento = () => {
  return (
    <article id="formPag">
      <div className="container">
        <h2 className="title">Formas de pagamento</h2>

        <p className="text">
          crédito, débito, ESPÉCIE, PIX e depósito bancário
        </p>

        <figure>
          <img src={formPag} alt="FORMAS DE PAGAMENTO" />
        </figure>
      </div>
    </article>
  );
};

export default FormasPagamento;
