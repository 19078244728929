import "./styles.css";

import CTAWpp from "../../components/CTAWpp";
import Carousel from "../../components/Carousel";
import CtaTelWpp from "../../components/CTATelWpp";

import logo_topo from "../../assets/logo_topo.png";
import header2_1 from "../../assets/header2_1.png";
import header2_2 from "../../assets/header2_2.png";
import header2_3 from "../../assets/header2_3.png";
import header2_4 from "../../assets/header2_4.png";
import header2_5 from "../../assets/header2_5.png";
import header2_6 from "../../assets/header2_6.png";
import header2_7 from "../../assets/header2_7.png";
import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";
import menu from "../../assets/menu.png";

const arr = [
  {
    image: header2_1,
  },
  {
    image: header2_2,
  },
  {
    image: header2_3,
  },
  {
    image: header2_4,
  },
  {
    image: header2_5,
  },
  {
    image: header2_6,
  },
  {
    image: header2_7,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <figure className="top">
          <img src={logo_topo} alt="Logo" />
        </figure>

        <article className="container">
          <h1 className="title">Centro de Tratamento Terapêutico Recomeço</h1>

          <p className="text">
            Trabalhamos com internação voluntária e involuntária e fazemos
            remoção pra toda Teresina e região.
          </p>

          <CTAWpp />
        </article>
      </section>

      <section className="mobile">
        <article className="container">
          <div className="left">
            <figure>
              <img src={menu} alt="menu" />
            </figure>

            <p className="text">Atuamos em toda a Teresina, Piauí</p>
          </div>

          <article className="right">
            <CtaTelWpp />
          </article>
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <div className="left">
            <h2 className="title">Sobre nós</h2>

            <p className="text">
              Centro de Tratamento Terapêutico Recomeço oferece conforto pra com
              todos os quartos climatizados. Aqui, seu ente querido, tem direito
              a quatro refeições diárias. Nossa assistência médica inclui
              Psicólogo, Psiquiatra, serviço de enfermagem com enfermeira, Tec.
              de enfermagem e temos também aula com o nosso terapeuta.
            </p>

            <CTAWpp />
          </div>

          <Carousel arr={arr} isVideo={false} slidesToShow={1} />
        </article>
      </section>

      <section className="header3">
        <article className="container vertical">
          <h2 className="title">Nossa missão</h2>

          <p className="text">
            Temos como objetivo, resgatar a saúde e dignidade de pessoas com
            dependências ou transtornos mentais causadas por:
          </p>

          <div className="gallery">
            <figure>
              <img src={header3_1} alt="Dependência Alcóolica" />
            </figure>

            <figure>
              <img src={header3_2} alt="Dependência Química" />
            </figure>

            <figure>
              <img src={header3_3} alt="Distúrbios Psicológicos" />
            </figure>
          </div>

          <p className="text text2">
            Fale conosco e agende uma visita sem compromisso em nossa unidade
            mais próxima:
          </p>

          <CTAWpp />
        </article>
      </section>
    </header>
  );
};

export default Header;
