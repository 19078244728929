import "./styles.css";

import CTAWpp from "../../components/CTAWpp";
import CtaTelWpp from "../../components/CTATelWpp";
import FormasPagamento from "../../components/FormasPagamento";

import about1 from "../../assets/about1.png";
import about2 from "../../assets/about2.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <div className="left">
            <h2 className="title">
              Porque escolher o Centro de Tratamento Terapêutico Recomeço?
            </h2>

            <p className="text">
              Com acolhimento, estrutura e o tratamento correto, conseguiremos
              ressocializar o seu ente querido. Nossa estrutura e equipe estarão
              sempre preparada para atendê-lo!
            </p>

            <CTAWpp />
          </div>

          <figure className="right">
            <img src={about1} alt="Médica com uma pasta na mão" />
          </figure>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="content">
            <figure className="left">
              <img src={about2} alt="Ambulância" />
            </figure>

            <p className="text">
              Fazemos remoções, voluntárias e involuntárias, em toda a Teresina,
              Piauí.
            </p>
          </div>
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">Cuide bem de quem você ama!</h2>

          <p className="text">
            <b>
              Trabalhamos com métodos eficazes, visando a uma recuperação de
              forma humanizada e natural.
            </b>{" "}
            Fale conosco agora mesmo por WhastApp ou Telefone:
          </p>

          <CtaTelWpp />
        </div>
      </article>

      <FormasPagamento />
    </section>
  );
};

export default About;
