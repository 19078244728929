import { useEffect, useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = (props) => {
  const [text, setText] = useState("Agende uma visita");
  const [number, setNumber] = useState("86988860534");

  useEffect(() => {
    if (props.text !== undefined) setText(props.text);
    if (props.number !== undefined) setNumber(props.number);
  }, [props.text, props.number]);

  return (
    <button id="ctaWpp">
      <a
        href={`http://wa.me/+55${number}?text=Olá,%20Gostaria%20de%20mais%20informações`}
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Whatsapp" />
        </figure>

        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
