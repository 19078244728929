import React from "react";
import "./index.css";

import CtaTel from "../CTATel";
import CtaWpp from "../CTAWpp";

const CtaTelWpp = () => {
  return (
    <article id="CtaTelWpp">
      <CtaWpp text={"(86) 98886-0534"} number={"86988860534"} />
      <CtaWpp text={"(86) 98118-3358"} number={"86981183358"} />
      <CtaTel />
    </article>
  );
};

export default CtaTelWpp;
